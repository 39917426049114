.adoc article, .adoc aside, .adoc details, .adoc figcaption, .adoc figure, .adoc footer, .adoc header, .adoc hgroup, .adoc main, .adoc nav, .adoc section, .adoc summary {
  display: block;
}

.adoc audio, .adoc canvas, .adoc video {
  display: inline-block;
}

.adoc audio:not([controls]) {
  height: 0;
  display: none;
}

.adoc [hidden], .adoc template {
  display: none;
}

.adoc script {
  display: none !important;
}

.adoc html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

.adoc body {
  margin: 0;
}

.adoc a {
  background: none;
}

.adoc a:focus {
  outline: thin dotted;
}

.adoc a:active, .adoc a:hover {
  outline: 0;
}

.adoc h1 {
  margin: .67em 0;
  font-size: 2em;
}

.adoc abbr[title] {
  border-bottom: 1px dotted;
}

.adoc b, .adoc strong {
  font-weight: bold;
}

.adoc dfn {
  font-style: italic;
}

.adoc hr {
  box-sizing: content-box;
  height: 0;
}

.adoc mark {
  color: #000;
  background: #ff0;
}

.adoc code, .adoc kbd, .adoc pre, .adoc samp {
  font-family: monospace, serif;
  font-size: 1em;
}

.adoc pre {
  white-space: pre-wrap;
}

.adoc q {
  quotes: "“" "”" "‘" "’";
}

.adoc small {
  font-size: 80%;
}

.adoc sub, .adoc sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.adoc sup {
  top: -.5em;
}

.adoc sub {
  bottom: -.25em;
}

.adoc img {
  border: 0;
}

.adoc svg:not(:root) {
  overflow: hidden;
}

.adoc figure {
  margin: 0;
}

.adoc fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

.adoc legend {
  border: 0;
  padding: 0;
}

.adoc button, .adoc input, .adoc select, .adoc textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}

.adoc button, .adoc input {
  line-height: normal;
}

.adoc button, .adoc select {
  text-transform: none;
}

.adoc button, .adoc html input[type="button"], .adoc input[type="reset"], .adoc input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.adoc button[disabled], .adoc html input[disabled] {
  cursor: default;
}

.adoc input[type="checkbox"], .adoc input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.adoc input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

.adoc input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.adoc input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.adoc button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.adoc input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.adoc textarea {
  vertical-align: top;
  overflow: auto;
}

.adoc table {
  border-collapse: collapse;
  border-spacing: 0;
}

.adoc meta.foundation-mq-small {
  width: 768px;
  font-family: "only screen and (min-width: 768px)";
}

.adoc meta.foundation-mq-medium {
  width: 1280px;
  font-family: "only screen and (min-width:1280px)";
}

.adoc meta.foundation-mq-large {
  width: 1440px;
  font-family: "only screen and (min-width:1440px)";
}

.adoc *, .adoc :before, .adoc :after {
  box-sizing: border-box;
}

.adoc html, .adoc body {
  font-size: 100%;
}

.adoc body {
  color: #000000de;
  cursor: auto;
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, DejaVu Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
}

.adoc a:hover {
  cursor: pointer;
}

.adoc img, .adoc object, .adoc embed {
  max-width: 100%;
  height: auto;
}

.adoc object, .adoc embed {
  height: 100%;
}

.adoc img {
  -ms-interpolation-mode: bicubic;
}

.adoc #map_canvas img, .adoc #map_canvas embed, .adoc #map_canvas object, .adoc .map_canvas img, .adoc .map_canvas embed, .adoc .map_canvas object {
  max-width: none !important;
}

.adoc .left {
  float: left !important;
}

.adoc .right {
  float: right !important;
}

.adoc .text-left {
  text-align: left !important;
}

.adoc .text-right {
  text-align: right !important;
}

.adoc .text-center {
  text-align: center !important;
}

.adoc .text-justify {
  text-align: justify !important;
}

.adoc .hide {
  display: none;
}

.adoc .antialiased {
  -webkit-font-smoothing: antialiased;
}

.adoc img {
  vertical-align: middle;
  display: inline-block;
}

.adoc textarea {
  height: auto;
  min-height: 50px;
}

.adoc select {
  width: 100%;
}

.adoc object, .adoc svg {
  vertical-align: middle;
  display: inline-block;
}

.adoc .center {
  margin-left: auto;
  margin-right: auto;
}

.adoc .stretch {
  width: 100%;
}

.adoc p.lead {
  font-size: 1.21875em;
  line-height: 1.6;
}

.adoc .subheader, .adoc .admonitionblock td.content > .title, .adoc .audioblock > .title, .adoc .exampleblock > .title, .adoc .imageblock > .title, .adoc .listingblock > .title, .adoc .literalblock > .title, .adoc .stemblock > .title, .adoc .openblock > .title, .adoc .paragraph > .title, .adoc .quoteblock > .title, .adoc table.tableblock > .title, .adoc .verseblock > .title, .adoc .videoblock > .title, .adoc .dlist > .title, .adoc .olist > .title, .adoc .ulist > .title, .adoc .qlist > .title, .adoc .hdlist > .title {
  color: #000;
  margin-top: 0;
  margin-bottom: .25em;
  font-weight: normal;
  line-height: 1.45;
}

.adoc div, .adoc dl, .adoc dt, .adoc dd, .adoc ul, .adoc ol, .adoc li, .adoc h1, .adoc h2, .adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title, .adoc h4, .adoc h5, .adoc h6, .adoc pre, .adoc form, .adoc p, .adoc blockquote, .adoc th, .adoc td {
  direction: ltr;
  margin: 0;
  padding: 0;
}

.adoc a {
  color: #2156a5;
  line-height: inherit;
  text-decoration: underline;
}

.adoc a:hover, .adoc a:focus {
  color: #1d4b8f;
}

.adoc a img {
  border: none;
}

.adoc p {
  text-rendering: optimizelegibility;
  margin-bottom: 1.25em;
  font-family: inherit;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.6;
}

.adoc p aside {
  font-size: .875em;
  font-style: italic;
  line-height: 1.35;
}

.adoc h1, .adoc h2, .adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title, .adoc h4, .adoc h5, .adoc h6 {
  color: #000;
  text-rendering: optimizelegibility;
  margin-top: 1em;
  margin-bottom: .5em;
  font-family: Open Sans, DejaVu Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.0125em;
}

.adoc h1 small, .adoc h2 small, .adoc h3 small, .adoc #toctitle small, .adoc .sidebarblock > .content > .title small, .adoc h4 small, .adoc h5 small, .adoc h6 small {
  color: #4d4d4d;
  font-size: 60%;
  line-height: 0;
}

.adoc h1 {
  font-size: 2.125em;
}

.adoc h2 {
  font-size: 1.6875em;
}

.adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title {
  font-size: 1.375em;
}

.adoc h4, .adoc h5 {
  font-size: 1.125em;
}

.adoc h6 {
  font-size: 1em;
}

.adoc hr {
  clear: both;
  border: 0 solid #dddddf;
  border-top-width: 1px;
  height: 0;
  margin: 1.25em 0 1.1875em;
}

.adoc em, .adoc i {
  font-style: italic;
  line-height: inherit;
}

.adoc strong, .adoc b {
  font-weight: bold;
  line-height: inherit;
}

.adoc small {
  font-size: 60%;
  line-height: inherit;
}

.adoc code {
  color: #000000e6;
  font-family: Fira Mono, Droid Sans Mono, DejaVu Sans Mono, monospace;
  font-weight: normal;
}

.adoc ul, .adoc ol, .adoc dl {
  margin-bottom: 1.25em;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.6;
  list-style-position: outside;
}

.adoc ul, .adoc ol, .adoc ul.no-bullet, .adoc ol.no-bullet {
  margin-left: 1.5em;
}

.adoc ul li ul, .adoc ul li ol {
  margin-bottom: 0;
  margin-left: 1.25em;
  font-size: 1em;
}

.adoc ul.square li ul, .adoc ul.circle li ul, .adoc ul.disc li ul {
  list-style: inherit;
}

.adoc ul.square {
  list-style-type: square;
}

.adoc ul.circle {
  list-style-type: circle;
}

.adoc ul.disc {
  list-style-type: disc;
}

.adoc ul.no-bullet {
  list-style: none;
}

.adoc ol li ul, .adoc ol li ol {
  margin-bottom: 0;
  margin-left: 1.25em;
}

.adoc dl dt {
  margin-bottom: .3125em;
  font-weight: bold;
}

.adoc dl dd {
  margin-bottom: 1.25em;
}

.adoc abbr, .adoc acronym {
  text-transform: uppercase;
  color: #000000de;
  cursor: help;
  border-bottom: 1px dotted #ddd;
  font-size: 90%;
}

.adoc abbr {
  text-transform: none;
}

.adoc blockquote {
  border-left: 1px solid #ddd;
  margin: 0 0 1.25em;
  padding: .5625em 1.25em 0 1.1875em;
}

.adoc blockquote cite {
  color: #0009;
  font-size: .9375em;
  display: block;
}

.adoc blockquote cite:before {
  content: "— ";
}

.adoc blockquote cite a, .adoc blockquote cite a:visited {
  color: #0009;
}

.adoc blockquote, .adoc blockquote p {
  color: #000000de;
  line-height: 1.6;
}

.adoc .vcard {
  border: 1px solid #ddd;
  margin: 0 0 1.25em;
  padding: .625em .75em;
  display: inline-block;
}

.adoc .vcard li {
  margin: 0;
  display: block;
}

.adoc .vcard .fn {
  font-size: .9375em;
  font-weight: bold;
}

.adoc .vevent .summary {
  font-weight: bold;
}

.adoc .vevent abbr {
  cursor: auto;
  border: none;
  padding: 0 .0625em;
  font-weight: bold;
  text-decoration: none;
}

@media only screen and (width >= 768px) {
  .adoc h1, .adoc h2, .adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title, .adoc h4, .adoc h5, .adoc h6 {
    line-height: 1.2;
  }

  .adoc h1 {
    font-size: 2.75em;
  }

  .adoc h2 {
    font-size: 2.3125em;
  }

  .adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title {
    font-size: 1.6875em;
  }

  .adoc h4 {
    font-size: 1.4375em;
  }
}

.adoc table {
  background: #fff;
  border: 1px solid #dedede;
  margin-bottom: 1.25em;
}

.adoc table thead, .adoc table tfoot {
  background: #f7f8f7;
  font-weight: bold;
}

.adoc table thead tr th, .adoc table thead tr td, .adoc table tfoot tr th, .adoc table tfoot tr td {
  font-size: inherit;
  color: #000000de;
  text-align: left;
  padding: .5em .625em .625em;
}

.adoc table tr th, .adoc table tr td {
  font-size: inherit;
  color: #000000de;
  padding: .5625em .625em;
}

.adoc table tr.even, .adoc table tr.alt, .adoc table tr:nth-of-type(2n) {
  background: #f8f8f7;
}

.adoc table thead tr th, .adoc table tfoot tr th, .adoc table tbody tr td, .adoc table tr td, .adoc table tfoot tr td {
  line-height: 1.6;
  display: table-cell;
}

.adoc body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  tab-size: 4;
}

.adoc h1, .adoc h2, .adoc h3, .adoc #toctitle, .adoc .sidebarblock > .content > .title, .adoc h4, .adoc h5, .adoc h6 {
  word-spacing: -.05em;
  line-height: 1.2;
}

.adoc h1 strong, .adoc h2 strong, .adoc h3 strong, .adoc #toctitle strong, .adoc .sidebarblock > .content > .title strong, .adoc h4 strong, .adoc h5 strong, .adoc h6 strong {
  font-weight: 400;
}

.adoc .clearfix:before, .adoc .clearfix:after, .adoc .float-group:before, .adoc .float-group:after {
  content: " ";
  display: table;
}

.adoc .clearfix:after, .adoc .float-group:after {
  clear: both;
}

.adoc :not(pre):not([class^="L"]) > code {
  letter-spacing: 0;
  word-spacing: -.15em;
  text-rendering: optimizespeed;
  word-wrap: break-word;
  background-color: #f7f7f8;
  border-radius: 4px;
  padding: .1em .5ex;
  font-size: .9375em;
  line-height: 1.45;
  font-style: normal !important;
}

.adoc :not(pre) > code.nobreak {
  word-wrap: normal;
}

.adoc :not(pre) > code.nowrap {
  white-space: nowrap;
}

.adoc pre {
  color: #000000e6;
  text-rendering: optimizespeed;
  font-family: Fira Mono, Droid Sans Mono, DejaVu Sans Mono, "Monospace", monospace;
  line-height: 1.45;
}

.adoc pre code, .adoc pre pre {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.adoc pre > code {
  display: block;
}

.adoc pre.nowrap, .adoc pre.nowrap pre {
  white-space: pre;
  word-wrap: normal;
}

.adoc em em {
  font-style: normal;
}

.adoc strong strong {
  font-weight: normal;
}

.adoc .keyseq {
  color: #333333de;
}

.adoc kbd {
  color: #000000de;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 .15em;
  padding: .2em .5em;
  font-family: Fira Mono, Droid Sans Mono, DejaVu Sans Mono, monospace;
  font-size: .65em;
  line-height: 1.45;
  display: inline-block;
  position: relative;
  top: -.1em;
  box-shadow: 0 1px #0003, inset 0 0 0 .1em #fff;
}

.adoc .keyseq kbd:first-child {
  margin-left: 0;
}

.adoc .keyseq kbd:last-child {
  margin-right: 0;
}

.adoc .menuseq, .adoc .menuref {
  color: #000;
}

.adoc .menuseq b:not(.caret), .adoc .menuref {
  font-weight: inherit;
}

.adoc .menuseq {
  word-spacing: -.02em;
}

.adoc .menuseq b.caret {
  font-size: 1.25em;
  line-height: .8;
}

.adoc .menuseq i.caret {
  text-align: center;
  width: .45em;
  font-weight: bold;
}

.adoc b.button:before, .adoc b.button:after {
  font-weight: normal;
  position: relative;
  top: -1px;
}

.adoc b.button:before {
  content: "[";
  padding: 0 3px 0 2px;
}

.adoc b.button:after {
  content: "]";
  padding: 0 2px 0 3px;
}

.adoc p a > code:hover {
  color: #000000e6;
}

.adoc #header, .adoc #content, .adoc #footnotes, .adoc #footer {
  zoom: 1;
  width: 100%;
  max-width: 62.5em;
  margin: 0 auto;
  padding-left: .9375em;
  padding-right: .9375em;
  position: relative;
}

.adoc #header:before, .adoc #header:after, .adoc #content:before, .adoc #content:after, .adoc #footnotes:before, .adoc #footnotes:after, .adoc #footer:before, .adoc #footer:after {
  content: " ";
  display: table;
}

.adoc #header:after, .adoc #content:after, .adoc #footnotes:after, .adoc #footer:after {
  clear: both;
}

.adoc #content {
  margin-top: 1.25em;
}

.adoc #content:before {
  content: none;
}

.adoc #header > h1:first-child {
  color: #000000de;
  margin-top: 2.25rem;
  margin-bottom: 0;
}

.adoc #header > h1:first-child + #toc {
  border-top: 1px solid #dddddf;
  margin-top: 8px;
}

.adoc #header > h1:only-child, body.toc2 .adoc #header > h1:nth-last-child(2) {
  border-bottom: 1px solid #dddddf;
  padding-bottom: 8px;
}

.adoc #header .details {
  color: #0009;
  border-bottom: 1px solid #dddddf;
  flex-flow: wrap;
  padding-top: .25em;
  padding-bottom: .25em;
  padding-left: .25em;
  line-height: 1.45;
  display: flex;
}

.adoc #header .details span:first-child {
  margin-left: -.125em;
}

.adoc #header .details span.email a {
  color: #000000de;
}

.adoc #header .details br {
  display: none;
}

.adoc #header .details br + span:before {
  content: " – ";
}

.adoc #header .details br + span.author:before {
  content: " ⋅ ";
  color: #000000de;
}

.adoc #header .details br + span#revremark:before {
  content: " | ";
}

.adoc #header #revnumber {
  text-transform: capitalize;
}

.adoc #header #revnumber:after {
  content: " ";
}

.adoc #content > h1:first-child:not([class]) {
  color: #000000de;
  border-bottom: 1px solid #dddddf;
  margin-top: 0;
  margin-bottom: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 8px;
}

.adoc #toc {
  border-bottom: 1px solid #e7e7e9;
  padding-bottom: .5em;
}

.adoc #toc > ul {
  margin-left: .125em;
}

.adoc #toc ul.sectlevel0 > li > a {
  font-style: italic;
}

.adoc #toc ul.sectlevel0 ul.sectlevel1 {
  margin: .5em 0;
}

.adoc #toc ul {
  font-family: Open Sans, DejaVu Sans, sans-serif;
  list-style-type: none;
}

.adoc #toc li {
  margin-top: .3334em;
  line-height: 1.3334;
}

.adoc #toc a {
  text-decoration: none;
}

.adoc #toc a:active {
  text-decoration: underline;
}

.adoc #toctitle {
  color: #000;
  font-size: 1.2em;
}

@media only screen and (width >= 768px) {
  .adoc #toctitle {
    font-size: 1.375em;
  }

  .adoc body.toc2 {
    padding-left: 15em;
    padding-right: 0;
  }

  .adoc #toc.toc2 {
    z-index: 1000;
    background: #f8f8f7;
    border-right: 1px solid #e7e7e9;
    width: 15em;
    height: 100%;
    padding: 1.25em 1em;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
    margin-top: 0 !important;
  }

  .adoc #toc.toc2 #toctitle {
    margin-top: 0;
    margin-bottom: .8rem;
    font-size: 1.2em;
  }

  .adoc #toc.toc2 > ul {
    margin-bottom: 0;
    font-size: .9em;
  }

  .adoc #toc.toc2 ul ul {
    margin-left: 0;
    padding-left: 1em;
  }

  .adoc #toc.toc2 ul.sectlevel0 ul.sectlevel1 {
    margin-top: .5em;
    margin-bottom: .5em;
    padding-left: 0;
  }

  .adoc body.toc2.toc-right {
    padding-left: 0;
    padding-right: 15em;
  }

  .adoc body.toc2.toc-right #toc.toc2 {
    border-left: 1px solid #e7e7e9;
    border-right-width: 0;
    left: auto;
    right: 0;
  }
}

@media only screen and (width >= 1280px) {
  .adoc body.toc2 {
    padding-left: 20em;
    padding-right: 0;
  }

  .adoc #toc.toc2 {
    width: 20em;
  }

  .adoc #toc.toc2 #toctitle {
    font-size: 1.375em;
  }

  .adoc #toc.toc2 > ul {
    font-size: .95em;
  }

  .adoc #toc.toc2 ul ul {
    padding-left: 1.25em;
  }

  .adoc body.toc2.toc-right {
    padding-left: 0;
    padding-right: 20em;
  }
}

.adoc #content #toc {
  background: #f8f8f7;
  border: 1px solid #e0e0dc;
  border-radius: 4px;
  margin-bottom: 1.25em;
  padding: 1.25em;
}

.adoc #content #toc > :first-child {
  margin-top: 0;
}

.adoc #content #toc > :last-child {
  margin-bottom: 0;
}

.adoc #footer {
  background: #000000de;
  max-width: 100%;
  padding: 1.25em;
}

.adoc #footer-text {
  color: #ffffffde;
  line-height: 1.44;
}

.adoc #content {
  margin-bottom: .625em;
}

.adoc .sect1 {
  padding-bottom: .625em;
}

@media only screen and (width >= 768px) {
  .adoc #content {
    margin-bottom: 1.25em;
  }

  .adoc .sect1 {
    padding-bottom: 1.25em;
  }
}

.adoc .sect1:last-child {
  padding-bottom: 0;
}

.adoc .sect1 + .sect1 {
  border-top: 1px solid #e7e7e9;
}

.adoc #content h1 > a.anchor, .adoc h2 > a.anchor, .adoc h3 > a.anchor, .adoc #toctitle > a.anchor, .adoc .sidebarblock > .content > .title > a.anchor, .adoc h4 > a.anchor, .adoc h5 > a.anchor, .adoc h6 > a.anchor {
  z-index: 1001;
  visibility: hidden;
  text-align: center;
  width: 1.5ex;
  margin-left: -1.5ex;
  font-weight: normal;
  display: block;
  position: absolute;
  text-decoration: none !important;
}

.adoc #content h1 > a.anchor:before, .adoc h2 > a.anchor:before, .adoc h3 > a.anchor:before, .adoc #toctitle > a.anchor:before, .adoc .sidebarblock > .content > .title > a.anchor:before, .adoc h4 > a.anchor:before, .adoc h5 > a.anchor:before, .adoc h6 > a.anchor:before {
  content: "§";
  padding-top: .1em;
  font-size: .85em;
  display: block;
}

.adoc #content h1:hover > a.anchor, .adoc #content h1 > a.anchor:hover, .adoc h2:hover > a.anchor, .adoc h2 > a.anchor:hover, .adoc h3:hover > a.anchor, .adoc #toctitle:hover > a.anchor, .adoc .sidebarblock > .content > .title:hover > a.anchor, .adoc h3 > a.anchor:hover, .adoc #toctitle > a.anchor:hover, .adoc .sidebarblock > .content > .title > a.anchor:hover, .adoc h4:hover > a.anchor, .adoc h4 > a.anchor:hover, .adoc h5:hover > a.anchor, .adoc h5 > a.anchor:hover, .adoc h6:hover > a.anchor, .adoc h6 > a.anchor:hover {
  visibility: visible;
}

.adoc #content h1 > a.link, .adoc h2 > a.link, .adoc h3 > a.link, .adoc #toctitle > a.link, .adoc .sidebarblock > .content > .title > a.link, .adoc h4 > a.link, .adoc h5 > a.link, .adoc h6 > a.link {
  color: #000;
  text-decoration: none;
}

.adoc #content h1 > a.link:hover, .adoc h2 > a.link:hover, .adoc h3 > a.link:hover, .adoc #toctitle > a.link:hover, .adoc .sidebarblock > .content > .title > a.link:hover, .adoc h4 > a.link:hover, .adoc h5 > a.link:hover, .adoc h6 > a.link:hover {
  color: #000;
}

.adoc details > summary:first-of-type {
  cursor: pointer;
  margin-bottom: 1.25em;
  display: list-item;
}

.adoc .audioblock, .adoc .imageblock, .adoc .literalblock, .adoc .listingblock, .adoc .stemblock, .adoc .videoblock {
  margin-bottom: 1.25em;
}

.adoc .admonitionblock td.content > .title, .adoc .audioblock > .title, .adoc .exampleblock > .title, .adoc .imageblock > .title, .adoc .listingblock > .title, .adoc .literalblock > .title, .adoc .stemblock > .title, .adoc .openblock > .title, .adoc .paragraph > .title, .adoc .quoteblock > .title, .adoc table.tableblock > .title, .adoc .verseblock > .title, .adoc .videoblock > .title, .adoc .dlist > .title, .adoc .olist > .title, .adoc .ulist > .title, .adoc .qlist > .title, .adoc .hdlist > .title {
  text-rendering: optimizelegibility;
  text-align: left;
  font-family: Fira Sans, DejaVu Sans, sans-serif;
  font-size: 1rem;
  font-style: italic;
}

.adoc table.tableblock.fit-content > caption.title {
  white-space: nowrap;
  width: 0;
}

.adoc .paragraph.lead > p, .adoc #preamble > .sectionbody > [class="paragraph"]:first-of-type p {
  color: #000000de;
  font-size: 1.21875em;
  line-height: 1.6;
}

.adoc table.tableblock #preamble > .sectionbody > [class="paragraph"]:first-of-type p {
  font-size: inherit;
}

.adoc .admonitionblock > table {
  border-collapse: separate;
  background: none;
  border: 0;
  width: 100%;
}

.adoc .admonitionblock > table td.icon {
  text-align: center;
  width: 80px;
}

.adoc .admonitionblock > table td.icon img {
  max-width: none;
}

.adoc .admonitionblock > table td.icon .title {
  text-transform: uppercase;
  font-family: Open Sans, DejaVu Sans, sans-serif;
  font-weight: bold;
}

.adoc .admonitionblock > table td.content {
  color: #0009;
  border-left: 1px solid #dddddf;
  padding-left: 1.125em;
  padding-right: 1.25em;
}

.adoc .admonitionblock > table td.content > :last-child > :last-child {
  margin-bottom: 0;
}

.adoc .exampleblock > .content {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 1.25em;
  padding: 1.25em;
}

.adoc .exampleblock > .content > :first-child {
  margin-top: 0;
}

.adoc .exampleblock > .content > :last-child {
  margin-bottom: 0;
}

.adoc .sidebarblock {
  background: #f3f3f2;
  border: 1px solid #dbdbd6;
  border-radius: 4px;
  margin-bottom: 1.25em;
  padding: 1.25em;
}

.adoc .sidebarblock > :first-child {
  margin-top: 0;
}

.adoc .sidebarblock > :last-child {
  margin-bottom: 0;
}

.adoc .sidebarblock > .content > .title {
  color: #000;
  text-align: center;
  margin-top: 0;
}

.adoc .exampleblock > .content > :last-child > :last-child, .adoc .exampleblock > .content .olist > ol > li:last-child > :last-child, .adoc .exampleblock > .content .ulist > ul > li:last-child > :last-child, .adoc .exampleblock > .content .qlist > ol > li:last-child > :last-child, .adoc .sidebarblock > .content > :last-child > :last-child, .adoc .sidebarblock > .content .olist > ol > li:last-child > :last-child, .adoc .sidebarblock > .content .ulist > ul > li:last-child > :last-child, .adoc .sidebarblock > .content .qlist > ol > li:last-child > :last-child {
  margin-bottom: 0;
}

.adoc .literalblock pre, .adoc .listingblock > .content > pre {
  word-wrap: break-word;
  border-radius: 4px;
  padding: 1em;
  font-size: .8125em;
  overflow-x: auto;
}

@media only screen and (width >= 768px) {
  .adoc .literalblock pre, .adoc .listingblock > .content > pre {
    font-size: .90625em;
  }
}

@media only screen and (width >= 1280px) {
  .adoc .literalblock pre, .adoc .listingblock > .content > pre {
    font-size: 1em;
  }
}

.adoc .literalblock.output pre {
  color: #f7f7f8;
  background-color: #000000e6;
}

.adoc .listingblock > .content > pre:not(.highlight), .adoc .listingblock > .content > pre[class="highlight"], .adoc .listingblock > .content > pre[class^="highlight "] {
  background: #f7f7f8;
}

.adoc .listingblock > .content {
  position: relative;
}

.adoc .listingblock code[data-lang]:before {
  content: attr(data-lang);
  text-transform: uppercase;
  color: inherit;
  opacity: .5;
  font-size: .75em;
  line-height: 1;
  display: none;
  position: absolute;
  top: .425rem;
  right: .5rem;
}

.adoc .listingblock:hover code[data-lang]:before {
  display: block;
}

.adoc .listingblock.terminal pre .command:before {
  content: attr(data-prompt);
  color: inherit;
  opacity: .5;
  padding-right: .5em;
}

.adoc .listingblock.terminal pre .command:not([data-prompt]):before {
  content: "$";
}

.adoc .listingblock pre.highlightjs {
  padding: 0;
}

.adoc .listingblock pre.highlightjs > code {
  border-radius: 4px;
  padding: 1em;
}

.adoc .listingblock pre.prettyprint {
  border-width: 0;
}

.adoc .prettyprint {
  background: #f7f7f8;
}

.adoc pre.prettyprint .linenums {
  margin-left: 2em;
  line-height: 1.45;
}

.adoc pre.prettyprint li {
  list-style-type: inherit;
  background: none;
  padding-left: 0;
}

.adoc pre.prettyprint li code[data-lang]:before {
  opacity: 1;
}

.adoc pre.prettyprint li:not(:first-child) code[data-lang]:before {
  display: none;
}

.adoc table.linenotable {
  border-collapse: separate;
  background: none;
  border: 0;
  margin-bottom: 0;
}

.adoc table.linenotable td[class] {
  color: inherit;
  vertical-align: top;
  line-height: inherit;
  white-space: normal;
  padding: 0;
}

.adoc table.linenotable td.code {
  padding-left: .75em;
}

.adoc table.linenotable td.linenos {
  opacity: .35;
  border-right: 1px solid;
  padding-right: .5em;
}

.adoc pre.pygments .lineno {
  opacity: .35;
  border-right: 1px solid;
  margin-right: .75em;
  display: inline-block;
}

.adoc pre.pygments .lineno:before {
  content: "";
  margin-right: -.125em;
}

.adoc .quoteblock {
  margin: 0 1em 1.25em 1.5em;
  display: table;
}

.adoc .quoteblock > .title {
  margin-bottom: .75em;
  margin-left: -1.5em;
}

.adoc .quoteblock blockquote, .adoc .quoteblock p {
  color: #000000de;
  word-spacing: .1em;
  letter-spacing: 0;
  text-align: justify;
  font-size: 1.15rem;
  font-style: italic;
  line-height: 1.75;
}

.adoc .quoteblock blockquote {
  border: 0;
  margin: 0;
  padding: 0;
}

.adoc .quoteblock blockquote:before {
  content: "“";
  float: left;
  color: #000;
  text-shadow: 0 1px 2px #0000001a;
  margin-left: -.6em;
  font-size: 2.75em;
  font-weight: bold;
  line-height: .6em;
}

.adoc .quoteblock blockquote > .paragraph:last-child p {
  margin-bottom: 0;
}

.adoc .quoteblock .attribution {
  text-align: right;
  margin-top: .75em;
  margin-right: .5ex;
}

.adoc .verseblock {
  margin: 0 1em 1.25em;
}

.adoc .verseblock pre {
  color: #000000de;
  text-rendering: optimizelegibility;
  font-family: Open Sans, DejaVu Sans, sans;
  font-size: 1.15rem;
  font-weight: 300;
}

.adoc .verseblock pre strong {
  font-weight: 400;
}

.adoc .verseblock .attribution {
  margin-top: 1.25rem;
  margin-left: .5ex;
}

.adoc .quoteblock .attribution, .adoc .verseblock .attribution {
  font-size: .9375em;
  font-style: italic;
  line-height: 1.45;
}

.adoc .quoteblock .attribution br, .adoc .verseblock .attribution br {
  display: none;
}

.adoc .quoteblock .attribution cite, .adoc .verseblock .attribution cite {
  letter-spacing: -.025em;
  color: #0009;
  display: block;
}

.adoc .quoteblock.abstract blockquote:before, .adoc .quoteblock.excerpt blockquote:before, .adoc .quoteblock .quoteblock blockquote:before {
  display: none;
}

.adoc .quoteblock.abstract blockquote, .adoc .quoteblock.abstract p, .adoc .quoteblock.excerpt blockquote, .adoc .quoteblock.excerpt p, .adoc .quoteblock .quoteblock blockquote, .adoc .quoteblock .quoteblock p {
  word-spacing: 0;
  line-height: 1.6;
}

.adoc .quoteblock.abstract {
  margin: 0 1em 1.25em;
  display: block;
}

.adoc .quoteblock.abstract > .title {
  text-align: center;
  margin: 0 0 .375em;
  font-size: 1.15em;
}

.adoc .quoteblock.excerpt, .adoc .quoteblock .quoteblock {
  border-left: .25em solid #dddddf;
  margin: 0 0 1.25em;
  padding: 0 0 .25em 1em;
}

.adoc .quoteblock.excerpt blockquote, .adoc .quoteblock.excerpt p, .adoc .quoteblock .quoteblock blockquote, .adoc .quoteblock .quoteblock p {
  color: inherit;
  font-size: 1.0625rem;
}

.adoc .quoteblock.excerpt .attribution, .adoc .quoteblock .quoteblock .attribution {
  color: inherit;
  text-align: left;
  margin-right: 0;
}

.adoc table.tableblock {
  border-collapse: separate;
  max-width: 100%;
}

.adoc p.tableblock:last-child {
  margin-bottom: 0;
}

.adoc td.tableblock > .content {
  margin-bottom: -1.25em;
}

.adoc table.tableblock, .adoc th.tableblock, .adoc td.tableblock {
  border: 0 solid #dedede;
}

.adoc table.grid-all > thead > tr > .tableblock, .adoc table.grid-all > tbody > tr > .tableblock {
  border-width: 0 1px 1px 0;
}

.adoc table.grid-all > tfoot > tr > .tableblock {
  border-width: 1px 1px 0 0;
}

.adoc table.grid-cols > * > tr > .tableblock {
  border-width: 0 1px 0 0;
}

.adoc table.grid-rows > thead > tr > .tableblock, .adoc table.grid-rows > tbody > tr > .tableblock {
  border-width: 0 0 1px;
}

.adoc table.grid-rows > tfoot > tr > .tableblock {
  border-width: 1px 0 0;
}

.adoc table.grid-all > * > tr > .tableblock:last-child, .adoc table.grid-cols > * > tr > .tableblock:last-child {
  border-right-width: 0;
}

.adoc table.grid-all > tbody > tr:last-child > .tableblock, .adoc table.grid-all > thead:last-child > tr > .tableblock, .adoc table.grid-rows > tbody > tr:last-child > .tableblock, .adoc table.grid-rows > thead:last-child > tr > .tableblock {
  border-bottom-width: 0;
}

.adoc table.frame-all {
  border-width: 1px;
}

.adoc table.frame-sides {
  border-width: 0 1px;
}

.adoc table.frame-topbot, .adoc table.frame-ends {
  border-width: 1px 0;
}

.adoc table.stripes-all tr, .adoc table.stripes-odd tr:nth-of-type(odd), .adoc table.stripes-even tr:nth-of-type(2n), .adoc table.stripes-hover tr:hover {
  background: #f8f8f7;
}

.adoc th.halign-left, .adoc td.halign-left {
  text-align: left;
}

.adoc th.halign-right, .adoc td.halign-right {
  text-align: right;
}

.adoc th.halign-center, .adoc td.halign-center {
  text-align: center;
}

.adoc th.valign-top, .adoc td.valign-top {
  vertical-align: top;
}

.adoc th.valign-bottom, .adoc td.valign-bottom {
  vertical-align: bottom;
}

.adoc th.valign-middle, .adoc td.valign-middle {
  vertical-align: middle;
}

.adoc table thead th, .adoc table tfoot th {
  font-weight: bold;
}

.adoc tbody tr th {
  background: #f7f8f7;
  line-height: 1.6;
  display: table-cell;
}

.adoc tbody tr th, .adoc tbody tr th p, .adoc tfoot tr th, .adoc tfoot tr th p {
  color: #000000de;
  font-weight: bold;
}

.adoc p.tableblock > code:only-child {
  background: none;
  padding: 0;
}

.adoc p.tableblock {
  font-size: 1em;
}

.adoc ol {
  margin-left: 1.75em;
}

.adoc ul li ol {
  margin-left: 1.5em;
}

.adoc dl dd {
  margin-left: 1.125em;
}

.adoc dl dd:last-child, .adoc dl dd:last-child > :last-child {
  margin-bottom: 0;
}

.adoc ol > li p, .adoc ul > li p, .adoc ul dd, .adoc ol dd, .adoc .olist .olist, .adoc .ulist .ulist, .adoc .ulist .olist, .adoc .olist .ulist {
  margin-bottom: .625em;
}

.adoc ul.checklist, .adoc ul.none, .adoc ol.none, .adoc ul.no-bullet, .adoc ol.no-bullet, .adoc ol.unnumbered, .adoc ul.unstyled, .adoc ol.unstyled {
  list-style-type: none;
}

.adoc ul.no-bullet, .adoc ol.no-bullet, .adoc ol.unnumbered {
  margin-left: .625em;
}

.adoc ul.unstyled, .adoc ol.unstyled {
  margin-left: 0;
}

.adoc ul.checklist {
  margin-left: .625em;
}

.adoc ul.checklist li > p:first-child > .fa-square-o:first-child, .adoc ul.checklist li > p:first-child > .fa-check-square-o:first-child {
  width: 1.25em;
  font-size: .8em;
  position: relative;
  bottom: .125em;
}

.adoc ul.checklist li > p:first-child > input[type="checkbox"]:first-child {
  margin-right: .25em;
}

.adoc ul.inline {
  flex-flow: wrap;
  margin: 0 0 .625em -1.25em;
  list-style: none;
  display: flex;
}

.adoc ul.inline > li {
  margin-left: 1.25em;
}

.adoc .unstyled dl dt {
  font-style: normal;
  font-weight: normal;
}

.adoc ol.arabic {
  list-style-type: decimal;
}

.adoc ol.decimal {
  list-style-type: decimal-leading-zero;
}

.adoc ol.loweralpha {
  list-style-type: lower-alpha;
}

.adoc ol.upperalpha {
  list-style-type: upper-alpha;
}

.adoc ol.lowerroman {
  list-style-type: lower-roman;
}

.adoc ol.upperroman {
  list-style-type: upper-roman;
}

.adoc ol.lowergreek {
  list-style-type: lower-greek;
}

.adoc .hdlist > table, .adoc .colist > table {
  background: none;
  border: 0;
}

.adoc .hdlist > table > tbody > tr, .adoc .colist > table > tbody > tr {
  background: none;
}

.adoc td.hdlist1, .adoc td.hdlist2 {
  vertical-align: top;
  padding: 0 .625em;
}

.adoc td.hdlist1 {
  padding-bottom: 1.25em;
  font-weight: bold;
}

.adoc .literalblock + .colist, .adoc .listingblock + .colist {
  margin-top: -.5em;
}

.adoc .colist td:not([class]):first-child {
  vertical-align: top;
  padding: .4em .75em 0;
  line-height: 1;
}

.adoc .colist td:not([class]):first-child img {
  max-width: none;
}

.adoc .colist td:not([class]):last-child {
  padding: .25em 0;
}

.adoc .thumb, .adoc .th {
  border: 4px solid #fff;
  line-height: 0;
  display: inline-block;
  box-shadow: 0 0 0 1px #ddd;
}

.adoc .imageblock.left {
  margin: .25em .625em 1.25em 0;
}

.adoc .imageblock.right {
  margin: .25em 0 1.25em .625em;
}

.adoc .imageblock > .title {
  margin-bottom: 0;
}

.adoc .imageblock.thumb, .adoc .imageblock.th {
  border-width: 6px;
}

.adoc .imageblock.thumb > .title, .adoc .imageblock.th > .title {
  padding: 0 .125em;
}

.adoc .image.left, .adoc .image.right {
  margin-top: .25em;
  margin-bottom: .25em;
  line-height: 0;
  display: inline-block;
}

.adoc .image.left {
  margin-right: .625em;
}

.adoc .image.right {
  margin-left: .625em;
}

.adoc a.image {
  text-decoration: none;
  display: inline-block;
}

.adoc a.image object {
  pointer-events: none;
}

.adoc sup.footnote, .adoc sup.footnoteref {
  vertical-align: super;
  font-size: .875em;
  position: static;
}

.adoc sup.footnote a, .adoc sup.footnoteref a {
  text-decoration: none;
}

.adoc sup.footnote a:active, .adoc sup.footnoteref a:active {
  text-decoration: underline;
}

.adoc #footnotes {
  margin-bottom: .625em;
  padding-top: .75em;
  padding-bottom: .75em;
}

.adoc #footnotes hr {
  border-width: 1px 0 0;
  width: 20%;
  min-width: 6.25em;
  margin: -.25em 0 .75em;
}

.adoc #footnotes .footnote {
  margin-bottom: .2em;
  margin-left: 1.2em;
  padding: 0 .375em 0 .225em;
  font-size: .875em;
  line-height: 1.3334;
}

.adoc #footnotes .footnote a:first-of-type {
  margin-left: -1.05em;
  font-weight: bold;
  text-decoration: none;
}

.adoc #footnotes .footnote:last-of-type {
  margin-bottom: 0;
}

#content .adoc #footnotes {
  margin-top: -.625em;
  margin-bottom: 0;
  padding: .75em 0;
}

.adoc .gist .file-data > table {
  background: #fff;
  border: 0;
  width: 100%;
  margin-bottom: 0;
}

.adoc .gist .file-data > table td.line-data {
  width: 99%;
}

.adoc div.unbreakable {
  page-break-inside: avoid;
}

.adoc .big {
  font-size: larger;
}

.adoc .small {
  font-size: smaller;
}

.adoc .underline {
  text-decoration: underline;
}

.adoc .overline {
  text-decoration: overline;
}

.adoc .line-through {
  text-decoration: line-through;
}

.adoc .aqua {
  color: #00bfbf;
}

.adoc .aqua-background {
  background-color: #00fafa;
}

.adoc .black {
  color: #000;
}

.adoc .black-background {
  background-color: #000;
}

.adoc .blue {
  color: #0000bf;
}

.adoc .blue-background {
  background-color: #0000fa;
}

.adoc .fuchsia {
  color: #bf00bf;
}

.adoc .fuchsia-background {
  background-color: #fa00fa;
}

.adoc .gray {
  color: #606060;
}

.adoc .gray-background {
  background-color: #7d7d7d;
}

.adoc .green {
  color: #006000;
}

.adoc .green-background {
  background-color: #007d00;
}

.adoc .lime {
  color: #00bf00;
}

.adoc .lime-background {
  background-color: #00fa00;
}

.adoc .maroon {
  color: #600000;
}

.adoc .maroon-background {
  background-color: #7d0000;
}

.adoc .navy {
  color: #000060;
}

.adoc .navy-background {
  background-color: #00007d;
}

.adoc .olive {
  color: #606000;
}

.adoc .olive-background {
  background-color: #7d7d00;
}

.adoc .purple {
  color: #600060;
}

.adoc .purple-background {
  background-color: #7d007d;
}

.adoc .red {
  color: #bf0000;
}

.adoc .red-background {
  background-color: #fa0000;
}

.adoc .silver {
  color: #909090;
}

.adoc .silver-background {
  background-color: #bcbcbc;
}

.adoc .teal {
  color: #006060;
}

.adoc .teal-background {
  background-color: #007d7d;
}

.adoc .white {
  color: #bfbfbf;
}

.adoc .white-background {
  background-color: #fafafa;
}

.adoc .yellow {
  color: #bfbf00;
}

.adoc .yellow-background {
  background-color: #fafa00;
}

.adoc span.icon > .fa {
  cursor: default;
}

a .adoc span.icon > .fa {
  cursor: inherit;
}

.adoc .admonitionblock td.icon [class^="fa icon-"] {
  text-shadow: 1px 1px 2px #00000080;
  cursor: default;
  font-size: 2.5em;
}

.adoc .admonitionblock td.icon .icon-note:before {
  content: "";
  color: #19407c;
}

.adoc .admonitionblock td.icon .icon-tip:before {
  content: "";
  text-shadow: 1px 1px 2px #9b9b00cc;
  color: #111;
}

.adoc .admonitionblock td.icon .icon-warning:before {
  content: "";
  color: #bf6900;
}

.adoc .admonitionblock td.icon .icon-caution:before {
  content: "";
  color: #bf3400;
}

.adoc .admonitionblock td.icon .icon-important:before {
  content: "";
  color: #bf0000;
}

.adoc .conum[data-value] {
  text-align: center;
  background-color: #000000de;
  border-radius: 100px;
  width: 1.67em;
  height: 1.67em;
  font-family: Open Sans, DejaVu Sans, sans-serif;
  font-size: .75em;
  font-style: normal;
  font-weight: bold;
  line-height: 1.67em;
  display: inline-block;
  color: #fff !important;
}

.adoc .conum[data-value] * {
  color: #fff !important;
}

.adoc .conum[data-value] + b {
  display: none;
}

.adoc .conum[data-value]:after {
  content: attr(data-value);
}

pre .adoc .conum[data-value] {
  position: relative;
  top: -.125em;
}

.adoc b.conum * {
  color: inherit !important;
}

.adoc .conum:not([data-value]):empty {
  display: none;
}

/*# sourceMappingURL=index.15e2043f.css.map */
